





































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import locationStoreModule from "@/store/modules/location";
import nearbySearchStoreModule from "@/store/modules/placeSearch";
import { Business, _Location, Role, PlaceSearchFromTextResult } from "@/types";

import LocationForm from "@/components/location/LocationForm.vue";

const { mapActions: locationActions, mapGetters: locationGetters } =
  createNamespacedHelpers("LOCATION_LIST");

const { mapActions: placeSearchActions } =
  createNamespacedHelpers("LOCATION_SEARCH");

export default Vue.extend<any, any, any, any>({
  name: "LocationList",
  components: { LocationForm },
  data: () => ({
    location: undefined as undefined | _Location,
    showLocationDialog: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Default", value: "isDefault" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    showLocationDeleteDialog: false,
  }),
  watch: {
    role() {
      if (this.role) {
        this.fetchLocations();
      }
    },
    options: {
      handler() {
        this.fetchLocations();
      },
      deep: true,
    },
    location() {
      if (this.location) {
        this.name = this.location.name;
      }
    },
  },
  computed: {
    ...locationGetters(["locationPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...locationActions(["fetchLocationList", "deleteLocation"]),
    ...placeSearchActions(["searchFromText"]),
    fetchLocations() {
      if (this.role) {
        this.fetchLocationList(
          `?businessId=${(this.role.business as Business)._id}&page=${
            this.options.page || 1
          }`
        );
      }
    },
    removeLocation(location: _Location) {
      this.deleteLocation(location._id).then((title) => {
        if (title) {
          this.showLocationDeleteDialog = false;
        }
      });
    },
    resetForm() {
      (
        this.$refs.titleForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();

      this.name = "";
    },
    searchLocation(q: string) {
      this.customLocation = q;
      if (q) {
        this.isLoadingLocations = true;
        let params = `?text=${q}`;
        if (this.latlng) params = params + `&latlng=${this.latlng}`;
        this.searchFromText(params).then((res: PlaceSearchFromTextResult) => {
          this.isLoadingLocations = false;
          if (res) {
            this.searchedLocations = res.candidates.map(
              (c) => c.formatted_address
            );
          }
        });
      }
    },
    closeModal(saved: boolean) {
      if (saved) {
        this.fetchLocations();
      }
      this.showLocationDialog = false;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("LOCATION_SEARCH")) {
      this.$store.registerModule("LOCATION_SEARCH", nearbySearchStoreModule);
    }
    if (!this.$store.hasModule("LOCATION_LIST")) {
      this.$store.registerModule("LOCATION_LIST", locationStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("LOCATION_SEARCH");
    this.$store.unregisterModule("LOCATION_LIST");
  },
});
