































































































































































































































































































































































































































































































































import Vue from "vue";
import {
  addShippingMethod,
  shippingMethods,
  updateShippingMethod,
  deleteShippingMethod,
} from "@/util/shipping";
import { Business, Role } from "@/types";

const weight_ranges = ["0 - 2", "3 - 5", "6 - 10", "11 - more"];

export default Vue.extend<any, any, any, any>({
  name: "ShippingMethods",
  data() {
    return {
      newMethodDialog: false,
      editMethodDialog: false,
      deleteMethodDialog: false,
      methods: [],
      weight_ranges,
      shippingMethod: {
        title: "",
        type: null as any,
        description: "",
        free_level: 0,
        max_weight: 0,
        rate: 0,
        dims: {
          l: 0,
          w: 0,
          h: 0,
        },
      },
      selectedMethod: undefined,
      headers: [
        { text: "Shipping Method", value: "title", sortable: false },
        { text: "Shipping Type", value: "type", sortable: false },
        { text: "Options", value: "options", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      shippingTypes: [
        {
          title: "Free Shipping",
          value: "free shipping",
          free_level: true,
          rate: false,
          max_weight: true,
          max_dims: false,
          local: false,
        },
        {
          title: "Local PickUp",
          value: "local pickup",
          free_level: false,
          rate: false,
          max_weight: false,
          max_dims: false,
          local: true,
        },
        {
          title: "Flat Rate",
          value: "flat rate",
          free_level: false,
          rate: false,
          max_weight: true,
          max_dims: true,
          local: false,
        },
        {
          title: "Calculated Shipping",
          value: "calculated shipping",
          free_level: false,
          rate: true,
          max_weight: false,
          max_dims: false,
          local: false,
        },
      ],
      range: {
        from: 0,
        to: 0,
        price: 0,
      },
      ranges: [] as { from: number; to: number; price: number }[],
    };
  },
  computed: {
    role(): Role {
      return this.$store.getters.role as Role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
  },
  created() {
    this.fetchShippingMethods();
  },
  watch: {
    selectedMethod(value) {
      if (value) {
        const { title, description, price, free_level, max_weight } = value;
        // this.method = {
        //   title,
        //   description,
        //   price,
        //   free_level,
        //   max_weight,
        // };
      } else {
        this.reset();
      }
    },
  },
  methods: {
    fetchShippingMethods() {
      if (this.role) {
        shippingMethods(this.vendor._id).then((response) => {
          this.methods = response.data.methods;
        });
      }
    },
    typeOptions(type: string) {
      return this.shippingTypes.filter((t) => t.value == type)[0] ?? {};
    },
    addRange() {
      const { from, to, price } = this.range;
      if (from && to && price) {
        if (this.editMethodDialog && this.selectedMethod) {
          (this.selectedMethod as any).rate_range.push({ from, to, price });
          this.range = { from: 0, to: 0, price: 0 };
          return;
        }
        this.ranges.push({ from, to, price });
        this.range = { from: 0, to: 0, price: 0 };
      }
    },
    dropRange(index: number) {
      if (this.editMethodDialog && this.selectedMethod) {
        (this.selectedMethod as any).rate_range.splice(index, 1);
        return;
      }
      this.ranges.splice(index, 1);
    },
    createMethod() {
      if (this.role) {
        const { title, description, type, free_level, max_weight, dims, rate } =
          this.shippingMethod;
        const payload = {
          title,
          description,
          dims,
          free_level,
          max_weight,
          type: type?.value,
          rate_range: this.ranges,
          rate,
        };
        addShippingMethod({ ...payload, business: this.vendor._id })
          .then(() => {
            this.newMethodDialog = false;
            this.reset();
            this.fetchShippingMethods();
          })
          .catch((error) => {
            this.$store.dispatch(
              "setToast",
              {
                title: "Creating Shipping method failed!",
                type: "error",
                text: error.response?.data?.error?.message,
              },
              { root: true }
            );
          });
      }
    },
    reset() {
      this.shippingMethod = {
        title: "",
        description: "",
        free_level: 0,
        rate: 0,
        max_weight: 0,
        type: null,
        dims: {
          l: 0,
          w: 0,
          h: 0,
        },
      };
      this.selectedMethod = undefined;
      this.ranges = [];
    },
    editMethod(item: any) {
      if (item) {
        this.selectedMethod = item;
        this.editMethodDialog = true;
      }
    },
    updateMethod() {
      if (this.selectedMethod) {
        const {
          _id,
          title,
          description,
          free_level,
          max_weight,
          dims,
          rate_range,
          rate,
          type,
        } = this.selectedMethod as any;
        console.log(dims);
        updateShippingMethod({
          id: _id,
          title,
          description,
          free_level,
          max_weight,
          dims,
          rate_range,
          rate,
          type: type?.value,
        })
          .then(() => {
            this.selectedMethod = undefined;
            this.editMethodDialog = false;
            this.fetchShippingMethods();
          })
          .catch((error) => {
            this.$store.dispatch(
              "setToast",
              {
                title: "Update failed!",
                type: "error",
                text: error.response?.data?.error?.message,
              },
              { root: true }
            );
          });
      }
    },
    dropMethod(id: string) {
      if (id) {
        deleteShippingMethod(id)
          .then(() => {
            this.fetchShippingMethods();
          })
          .catch((e) => console.log(e));
      }
    },
  },
});
