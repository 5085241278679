




import Vue from "vue";

import LocationList from "@/components/location/LocationList.vue";
export default Vue.extend<any, any, any, any>({
  components: { LocationList },
  name: "Location",
});
