import { Page } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

const actions = {
  SET_ZONE_PAGE: "SET_ZONE_PAGE",
  ADD_ZONE: "ADD_ZONE",
  REMOVE_ZONE: "REMOVE_ZONE",
  SET_SHIPPING_METHODS: "SET_SHIPPING_METHODS",
};

type ShippingState = {
  zonePage: Page<any>;
  methods: any[];
};

const shipping: Module<ShippingState, unknown> = {
  namespaced: true,
  state: () => ({
    zonePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    methods: [],
  }),
  getters: {
    getShippingZone: (state) => (zoneId: string) =>
      state.zonePage.docs.find((c) => c._id === zoneId),
    shippingZonePage: (state) => state.zonePage,
    shippingMethods: (state) => state.methods,
  },
  mutations: {
    [actions.ADD_ZONE](state, _zone) {
      const zone = state.zonePage.docs.filter((z: any) => z._id == _zone._id);
      if (zone.length == 0) {
        state.zonePage.docs.push(_zone);
      } else {
        state.zonePage.docs.map((item: any) => {
          return item._id == _zone._id ? _zone : item;
        });
      }
    },
    [actions.SET_ZONE_PAGE](state, _page) {
      state.zonePage = _page;
    },
    [actions.REMOVE_ZONE](state, _zone) {
      state.zonePage.docs = state.zonePage.docs.filter(
        (item: any) => item._id != _zone._id
      );
    },
    [actions.SET_SHIPPING_METHODS](state, _methods) {
      state.methods = _methods;
    },
  },
  actions: {
    async createShippingZone(context, _payload) {
      return await api
        .post(`/v1/shipping-zones`, _payload)
        .then((response) => {
          context.commit(actions.ADD_ZONE, response.data.zone);
          return response;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
          return error;
        });
    },
    async fetchShippingMethods(context, _query) {
      return await api
        .get(`/v1/shipping-methods${_query}`)
        .then((response) => {
          context.commit(actions.SET_SHIPPING_METHODS, response.data.methods);
          return response;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
          return error;
        });
    },
    async fetchShippingZones(context, _query) {
      return await api
        .get(`/v1/shipping-zones${_query}`)
        .then((response) => {
          context.commit(actions.SET_ZONE_PAGE, response.data.zonePage);
          return response;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
          return error;
        });
    },
    async fetchShippingZoneById(context, zoneId) {
      return await api
        .get(`/v1/shipping-zones?zoneId=${zoneId}`)
        .then((response) => {
          context.commit(actions.ADD_ZONE, response.data.zone);
          return response;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
          return error;
        });
    },
    async updateShippingZone(context, payload) {
      const { zoneId, ...data } = payload;
      return await api
        .put(`/v1/shipping-zones/${zoneId}`, { ...data })
        .then((response) => {
          context.commit(actions.REMOVE_ZONE, response.data.zone);
          return response;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
          return error;
        });
    },
    async deleteShippingZone(context, zoneId) {
      return await api
        .delete(`/v1/shipping-zones/${zoneId}`)
        .then((response) => {
          context.commit(actions.REMOVE_ZONE, response.data.zone);
          return response;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
          return error;
        });
    },
  },
};

export default shipping;
