var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"490"},model:{value:(_vm.showLocationDialog),callback:function ($$v) {_vm.showLocationDialog=$$v},expression:"showLocationDialog"}},[(_vm.showLocationDialog)?_c('location-form',{attrs:{"location":_vm.location},on:{"saved":_vm.closeModal}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"490"},model:{value:(_vm.showLocationDeleteDialog),callback:function ($$v) {_vm.showLocationDeleteDialog=$$v},expression:"showLocationDeleteDialog"}},[(_vm.location)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Delete Location ")]),_c('v-card-text',[_vm._v(" Confirm to delete "+_vm._s(_vm.location.name)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showLocationDeleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.removeLocation(_vm.location)}}},[_vm._v(" Delete ")])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.showLocationDialog = true;
          _vm.resetForm();}}},[_vm._v(" Add Location ")]),_vm._v("   "),_c('v-btn',{attrs:{"elevation":"0","small":""}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.locationPage.docs,"options":_vm.options,"server-items-length":_vm.locationPage.total,"no-data-text":"No locations to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.isDefault",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.isDefault ? "Yes" : "No")+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 mr-4",attrs:{"elevation":"1","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.location = item;
                _vm.showLocationDialog = true;}}},[_vm._v(" Edit ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.location = item;
              _vm.showLocationDeleteDialog = true;}}},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }