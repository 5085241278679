











































































































































































































































































































































































































import Vue from "vue";

import ShippingModule from "@/store/modules/shipping";
import { createNamespacedHelpers } from "vuex";
import { Business, Role } from "@/types";

const { mapGetters: shippingGetters, mapActions: shippingActions } =
  createNamespacedHelpers("_ShippingZones");

const Regions = [
  {
    region: "Kenya",
    counties: [
      "Nairobi",
      "Machakos",
      "Embu",
      "Kiambu",
      "Kitui",
      "Mombasa",
      "Kilifi",
      "Tana River",
      "Kwale",
      "Kajiado",
      "Garissa",
      "Mandera",
      "Isiolo",
      "Turkana",
      "Nyeri",
      "Bomet",
      "Kericho",
      "Nyamira",
      "Kisii",
      "Migori",
      "Kisumu",
      "Homa Bay",
      "Siaya",
      "Kakamega",
      "Nandi",
      "Uasin Gishu",
      "Nakuru",
    ],
  },
  {
    region: "Uganda",
    counties: ["Kampala", "Entebbe"],
  },
];

const countries = [
  "Uganda",
  "Tanzania",
  "Ethiopia",
  "Somalia",
  "Rwanda",
  "Burundi",
  "Eritrea",
  "Djibouti",
  "Nigeria",
  "Ghana",
  "Egypt",
  "South Africa",
];

interface Method {
  rate: number;
  shipping_method: string | any | undefined;
}

export default Vue.extend<any, any, any, any>({
  name: "ShippingZones",
  data: () => ({
    regions: Regions,
    newZoneDialog: false,
    editZoneDialog: false,
    deleteZoneDialog: false,
    selectedZone: null,
    zoneTypes: [
      { title: "Local Shipping Zone", value: "local" },
      { title: "International Shipping Zone", value: "international" },
    ],
    zone: {
      name: "",
      regions: [],
      type: "local",
      duration_estimate: {
        from: 0,
        to: 0,
      },
      methods: [] as Method[],
    },
    headers: [
      { text: "Shipping Zone", value: "name", sortable: false },
      { text: "Type", value: "type", sortable: false },
      { text: "Regions", value: "jurisdiction", sortable: false },
      { text: "Shipping Methods", value: "methods", sortable: false },
      { text: "Shipping Duration", value: "est_duration", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
  }),
  watch: {
    role() {
      this.loadShippingZones();
    },
  },
  computed: {
    ...shippingGetters([
      "shippingZonePage",
      "getShippingZone",
      "shippingMethods",
    ]),
    role(): Role {
      return this.$store.getters.role as Role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    localCountry(): string {
      return "Kenya";
    },
    _methods(): any[] {
      return this.shippingMethods || [];
    },
    _selectedMethods(): any[] {
      return this.zone.methods.map((m) => m.shipping_method);
    },
    states(): string[] {
      const { type } = this.zone;
      if (type == "local") {
        return this.regions.filter((r) => r.region == this.localCountry)[0]
          ?.counties;
      }

      if (type == "international") {
        return countries;
      }
      return [];
    },
    icon() {
      if (this.zone.regions.length == this.states.length)
        return "mdi-close-box";
      if (this.zone.regions.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  mounted() {
    this.loadShippingZones();
  },
  methods: {
    ...shippingActions([
      "fetchShippingZones",
      "fetchShippingMethods",
      "createShippingZone",
      "deleteShippingZone",
      "updateShippingZone",
    ]),
    loadShippingZones() {
      if (this.vendor) {
        this.fetchShippingZones(`?businessId=${this.vendor._id}`);
        this.fetchShippingMethods(`?businessId=${this.vendor._id}`);
      }
    },
    resetZone() {
      this.zone = {
        name: "",
        regions: [],
        type: "local",
        duration_estimate: {
          from: 0,
          to: 0,
        },
        methods: [] as Method[],
      };
    },
    toggle() {
      if (this.zone.regions.length == this.states.length) {
        this.zone.regions = [];
      } else {
        this.zone.regions = this.states as any;
      }
    },
    initEdit(zone: any) {
      if (this.vendor) {
        this.fetchShippingMethods(`?businessId=${this.vendor._id}`);
      }
      this.editZoneDialog = true;
      this.selectedZone = zone;
      this.zone = zone;
    },
    closeEdit() {
      this.selectedZone = null;
      this.resetZone();
      this.editZoneDialog = false;
    },
    updateZone() {
      if (this.selectedZone) {
        const { _id } = this.selectedZone as any;
        const { name, duration_estimate, methods, regions } = this.zone;
        this.updateShippingZone({
          zoneId: _id,
          name,
          regions,
          duration_estimate,
          methods: methods.map((m) => {
            return {
              rate: m.rate,
              shipping_method: m.shipping_method._id,
            };
          }),
        }).then(() => {
          this.closeEdit();
          this.loadShippingZones();
        });
      }
    },
    createZone() {
      if (this.vendor) {
        const { name, type, regions, methods, duration_estimate } = this.zone;
        const payload = {
          business: this.vendor._id,
          name,
          regions,
          type,
          duration_estimate,
          methods: methods.map((method) => {
            return {
              shipping_method: method.shipping_method._id,
              rate: method.rate,
            };
          }),
        };
        this.createShippingZone(payload).then(() => {
          this.newZoneDialog = false;
          this.resetZone();
        });
      }
    },
    methodSelected(e: any[]) {
      if (e.length > 0) {
        e.forEach((item) => {
          const isAvailable = this.zone.methods.filter(
            (z) => z.shipping_method._id == item._id
          )[0];
          const isDeleted = this.zone.methods.filter(
            (z) => !e.some((item2) => item2._id == z.shipping_method._id)
          )[0];
          if (!isAvailable) {
            this.zone.methods.push({ shipping_method: item, rate: 0 });
          }

          if (isDeleted) {
            const index = this.zone.methods.indexOf(isDeleted);
            this.zone.methods.splice(index, 1);
          }
        });
      } else {
        this.zone.methods = [];
      }
    },
    handleView(value: any) {
      return true;
    },
    dropZone(zoneId: string) {
      this.deleteShippingZone(zoneId).then(() => {
        this.loadShippingZones();
        this.deleteZoneDialog = false;
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("_ShippingZones")) {
      this.$store.registerModule("_ShippingZones", ShippingModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("_ShippingZones");
  },
});
