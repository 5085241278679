













import Vue from "vue";
import Zones from "@/components/shipping/shippingzones.vue";
import ShipMethods from "@/components/shipping/shippingmethods.vue";

export default Vue.extend<any, any, any, any>({
  name: "Shipping",
  components: { Zones, ShipMethods },
});
